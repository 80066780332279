















































































import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import { UserModule } from '@/store/modules/user'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsLink from '@advision/vision/src/components/utilities/VsLink/Index.vue'

@Component({
    name: 'VsSmsCard',
    components: {
        VsListingCard,
        VsLink,
        VsDropdownButton,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private sms!: any

    @Prop({
        default: null,
        required: false,
    }) private value!: string[]

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get smsStatus () {
        return this.sms?.status || 'default'
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get universityUrl () {
        return AppModule.universityUrl
    }

    get badgeStatusAspect () {
        switch (this.smsStatus) {
            case 'queued':
                return 'info'
            case 'sent':
                return 'primary'
            case 'delivered':
                return 'success'
            case 'bounce':
            case 'failed':
                return 'alert'
            case 'enabled':
            default:
                return 'grey'
        }
    }

    get badgeStatusIcon () {
        switch (this.smsStatus) {
            case 'queued':
                return 'clock'
            case 'sent':
                return 'outgoing'
            case 'delivered':
                return 'double-check'
            case 'bounce':
            case 'failed':
                return 'error'
            case 'enabled':
            default:
                return 'edit'
        }
    }

    get defaultImage () {
        return require('@/assets/img/campaigns/sms-placeholder.png')
    }

    get previewImage () {
        return this.defaultImage
    }
}
