






















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsSmsSingleTargetCard',
    components: {
        VsLoader,
        VsCollapseCard,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) target!: string
    @Prop({ required: true, default: '' }) tabOpen!: string
    loading = false
    tempTarget = ''
    $refs: any

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    get targetCardStatus () {
        return this.target ? 'success' : 'default'
    }

    openTab () {
        this.$emit('open-tab', 'singleTarget')
    }

    private openTabTarget () {
        this.tempTarget = this.target
        this.openTab()
    }

    private async saveTarget () {
        const valid = await this.$refs.targetform.validate()
        if (!valid) {
            this.$root.$vsToast({
                heading: 'Il destinatario inserito non è valido',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            return
        }
        this.$emit('target-saved', this.tempTarget)
        this.$emit('open-tab', '')
    }

    private async openConfirmDraft () {
        this.$emit('open-confirm-draft')
    }
}
