































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import { deleteSms, getSms } from '@/api/consoleApi/singleSms'
import VsEmptyState from '@/components/VsEmptyState/Index.vue'
import VsSmsCard from '@/modules/sms/components/VsSmsCard/Index.vue'
import VsSendSmsModal from '@/modules/sms/components/VsSendSmsModal/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsSmsPreviewModal from '@/modules/sms/components/VsSmsPreviewModal/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'SmsIndex',
    components: {
        VsContainer,
        VsEmptyState,
        VsSendSmsModal,
        VsSectionHeader,
        VsSmsPreviewModal,
        VsSmsCard,
    },
})
export default class extends Vue {
    private loading = false
    private sms: any[] = []
    private total = 0
    showEmpty = false
    $refs!: any

    private filters = {
        search: '',
        labelTags: [],
        type: null,
        status: [],
    }

    private pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    get smsOrderBy () {
        return [
            {
                label: this.$t('campaigns.index.orderBy.createdAtDesc'),
                value: 'id|desc',
            },
            {
                label: this.$t('campaigns.index.orderBy.createdAtAsc'),
                value: 'id|asc',
            },
        ]
    }

    async beforeMount () {
        await this.getSms()
        if (this.total === 0) this.showEmpty = true
    }

    @Watch('filters', { deep: true, immediate: false })
    @Watch('pagination', { deep: true, immediate: false })
    async getSms () {
        this.loading = true
        try {
            const params = {
                orderBy: 'id',
                sortedBy: 'desc',
                page: this.pagination.page,
                limit: this.pagination.itemsPerPage,
            }

            if (this.pagination.orderBy) {
                const order = this.pagination.orderBy.split('|')
                params.orderBy = order[0]
                params.sortedBy = order[1]
            }

            const resp = await getSms(params)
            this.sms = resp.data.data
            this.total = resp.data.meta.pagination.total
            if (this.total > 0) this.showEmpty = false
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    async deleteSms (smsId: any) {
        this.loading = true
        try {
            await deleteSms(smsId)
            this.getSms()
            this.$root.$vsToast({
                heading: 'Sms eliminato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante l \'eliminazione del messaggio',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        }
        this.loading = false
    }

    private openPreviewModal (sms: any) {
        this.$refs.previewModal.openModalNew(sms, 'sms')
    }
}
