import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getSms = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/sms`,
        {
            cancelToken,
            params,
        },
    )

export const createAndSendSms = (data: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/sms`,
        data,
        {
            cancelToken,
            params,
        },
    )

export const deleteSms = (id: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/sms/${id}`,
        {
            cancelToken,
            params,
        },
    )
