

















































import { Component, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import VsPhonePreview from '@/modules/campaigns/components/VsPhonePreview/Index.vue'
import { SmsCounter } from '@/utils/smsCounter'

@Component({
    name: 'VsSmsPreviewModal',
    components: {
        VsLoader,
        VsFullTopBarLayout,
        VsContainer,
        VsSeparator,
        VsPhonePreview,
    },
})
export default class extends Vue {
    private open = false
    private sender: any = false
    private htmlPreview = ''
    private loading = false
    private smsCounter = new SmsCounter()

    $refs: any

    get combinedSender () {
        if (!this.sender) return 'nd'
        return this.sender
    }

    get previewCounter () {
        if (!this.htmlPreview) return false
        return this.smsCounter.count(this.htmlPreview)
    }

    public openModalNew (sms: any) {
        this.open = true
        this.htmlPreview = sms.body
        this.sender = sms.from
    }

    private closeModal () {
        this.open = false
    }
}
