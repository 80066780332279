






























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSmsContentCard from '@/modules/campaigns/components/VsSmsContentCard/Index.vue'
import VsSmsSenderCard from '@/modules/campaigns/components/VsSmsSenderCard/Index.vue'
import VsSmsSingleTargetCard from '@/modules/sms/components/VsSmsSingleTargetCard/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { createAndSendSms } from '@/api/consoleApi/singleSms'
import { get } from 'lodash'

@Component({
    name: 'VsSendSmsModal',
    components: {
        VsContainer,
        VsSectionHeader,
        VsFullTopBarLayout,
        VsLoader,
        VsConfirm,
        VsSmsContentCard,
        VsSmsSingleTargetCard,
        VsSmsSenderCard,
    },
})
export default class extends Vue {
    private open = false
    @Prop({ required: false, default: false, type: Boolean }) isEdit!: boolean
    private loading = false
    private sms: any | null = null
    private tabOpen = ''
    private smsError = false
    private missingSteps: any[] = []
    $refs: any

    private calculateMissingSteps () {
        const missingSteps = []
        if (this.$refs.vsSmsSenderCard && (this.$refs.vsSmsSenderCard.senderCardStatus === 'error' || this.$refs.vsSmsSenderCard.senderCardStatus === 'default')) missingSteps.push('sender')
        if (this.$refs.vsSmsTargetCard.targetCardStatus === 'default') missingSteps.push('singleTarget')
        if (this.$refs.vsSmsContentCard.campaignContentStatus === 'error' || this.$refs.vsSmsContentCard.campaignContentStatus === 'default') missingSteps.push('content')
        return missingSteps
    }

    smsHasError () {
        this.missingSteps = this.calculateMissingSteps()
        return this.missingSteps.length > 0
    }

    public async openModal (to = '') {
        this.loading = true
        this.sms = {
            from: '',
            to,
            body: '',
        }
        this.open = true
        this.loading = false
    }

    public closeModal () {
        this.open = false
    }

    private openTab (tabName: string) {
        this.tabOpen = tabName
    }

    private async save () {
        if (this.smsHasError()) {
            this.smsError = true
            this.$root.$vsToast({
                heading: 'Non è possibile inviare il messaggio, controlla gli errori e riprova',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            return
        }
        this.loading = true
        try {
            await createAndSendSms(this.sms)
            this.$emit('sms-sent', this.sms)
            this.$root.$vsToast({
                heading: 'SMS inviato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.closeModal()
        } catch (e) {
            let message = ''
            const error = get(e, 'response.data.message', '')
            if (error === 'No enough sms credits to send message') {
                message = 'Crediti insufficienti'
            }
            this.$root.$vsToast({
                heading: 'Errore durante l\'invio dell\' SMS',
                message,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.loading = false
    }

    targetSaved (e: any) {
        this.missingSteps = []
        this.smsError = false
        this.sms.to = e
    }

    senderSaved (e: any) {
        this.missingSteps = []
        this.smsError = false
        this.sms.from = e
    }

    contentSaved (body: any) {
        if (!this.sms) return
        this.missingSteps = []
        this.smsError = false
        this.sms.body = body
    }
}
